<template>
  <div class="mw8 center">
    <h1 class="profile-page-title">{{ currentUser.name }}</h1>
    <p>Logged in with {{ provider }}</p>
    <p v-if="status" class="pa2 bg-light-yellow b">{{ status }}</p>
    <div
      class="pv2 dib ph2 dark-red hover-bg-dark-red hover-white link no-underline pointer ba bw1 b--red"
      @click="logout()"
    >
      Logout
    </div>

    <div v-if="provider === 'password'" class="">
      <label for="user-email" class="b mv2 db">Member Email</label>
      <p class="i">
        This is the email address used to manage your account. This address is
        not published. If you want to publish your email for other users, please
        use the contacts section.
      </p>
      <validation-error :show="$v.email.$invalid">
        Please enter a valid email address.
      </validation-error>
      <input
        id="user-email"
        v-model.trim="email"
        placeholder="user email..."
        type="text"
        class="pa2 w-100 mv3"
      />
      <div
        v-if="!$v.email.$invalid"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib mv3"
        @click="changeEmail()"
      >
        <i class="las la-save"></i>
        Change Email
      </div>
      <div
        v-show="$v.email.$invalid"
        class="pa2 b-grey bw1 ba br1 o-80 dib mv3"
      >
        <i class="las la-exclamation-triangle"></i>
        Cannot Change Email Due to Errors
      </div>
      <h1>Change Password</h1>
      <label for="password" class="b mv2 dib">Password</label>
      <div v-show="$v.password.$invalid" class="red b f6">
        Password must be at least 6 characters
      </div>
      <input
        id="password"
        v-model.trim="password"
        placeholder="password..."
        type="password"
        class="pa2 w-100 mv3"
      />
      <label for="password2" class="b mv2 dib">Repeat Password</label>
      <div v-show="$v.password2.$invalid" class="red b f6">
        Passwords must match
      </div>
      <input
        id="password2"
        v-model.trim="password2"
        placeholder="password..."
        type="password"
        class="pa2 w-100 mv3"
      />

      <div
        v-if="!$v.password.$invalid && !$v.password2.$invalid"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib mv3"
        @click="changePassword()"
      >
        <i class="las la-save"></i>
        Change Password
      </div>
      <div
        v-show="$v.password.$invalid || $v.password2.$invalid"
        class="pa2 b-grey bw1 ba br1 o-80 dib mv3"
      >
        <i class="las la-exclamation-triangle"></i>
        Cannot Change Password Due to Errors
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState, mapGetters } from 'vuex'
import firebase from 'firebase/app'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      status: null,
      fbUser: null,
      email: '',
      password: '',
      password2: ''
    }
  },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['user']),
    ...mapGetters('users', ['getUserById']),
    currentUser() {
      const user = this.fbUser
      return this.getUserById(user.uid)
    },
    provider() {
      const user = this.fbUser

      if (user != null) {
        return user.providerData[0].providerId
      }

      return ''
    }
  },
  created() {
    this.fbUser = firebase.auth().currentUser
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
    },
    changePassword() {
      this.fbUser
        .updatePassword(this.password)
        .then(() => {
          // Update successful.
          this.password = ''
          this.password2 = ''
          this.status = 'Password Changed'
        })
        .catch(error => {
          // An error happened.
          this.status = error
        })
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    password2: {
      required,
      sameAsPassword: sameAs('password')
    },
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped></style>
